import React from "react";
import api from "../../../services/api";
import "../../../styles/buttonTransparencia.css";
import Loading from "../others/Loading";
import { imagemURL, TipoPortal, tokenLink } from "../../../services/variables";
import { useQuery } from "react-query";

export default function ButtonTransparenciaDinamicy() {
  const { data, isLoading } = useQuery(
    "BOTOES",
    async () => {
      const response = await api.get(`/v2/botoes/list/${TipoPortal}`);
      return response.data.res;
    },
    { staleTime: 1000 * 60 * 60 * 1, cacheTime: 1000 * 60 * 60 * 2 }
  );

  const buttonToken = (link) => {
    const txt = link.replace("{*}", tokenLink);
    return txt;
  };

  const getHref = (buttonName, buttonLink) => {
    switch (buttonName) {
      default:
        return buttonToken(buttonLink);
    }
  };

  return (
    <div className="box_buttons">
      <div className="back"></div>
      {isLoading && <Loading />}

      {!isLoading &&
        data &&
        data.map((item) => (
          <div key={item.ID_CATEGORIA} className="btn_container">
            <h4 id={item.CATEGORIA}>{item.CATEGORIA}</h4>
            <div className="btns_container">
              {item.BUTTONS.map((button) => (
                <a
                  key={button.ID}
                  className="btn_box"
                  href={getHref(button.NOME, button.LINK)}
                  title={button.NOME}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={imagemURL + button.IMG}
                    alt={button.NOME}
                    className="svg-button-transparencia"
                  />
                  <p>{button.NOME}</p>
                </a>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}
