const idPortalApi = "96406f9755b335f60c312b0fafd1da500db04c24";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmitaipava.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmitaipava.ma.gov.br",
  NomePortal: "Itaipava do Grajaú",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/VKYA18hKiYmUjP3w7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10465.093001800054!2d-45.795526997652765!3d-5.144105543278696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92cdb345889313c5%3A0xfce8f1ab87f9b18a!2sItaipava%20do%20Graja%C3%BA%2C%20State%20of%20Maranh%C3%A3o%2C%2065948-000!5e0!3m2!1sen!2sbr!4v1739047345924!5m2!1sen!2sbr",
};
